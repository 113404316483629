<template>
  <div class="generic-list orders-table-container">
    <v-list class="mt-4 orders-list">
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        :class="{ active: order.orderStatusId === 3 }"
        class="primary--text secondary lighten-2 rounded-sm"
      >
        <v-row no-gutters align="center" class="text-caption text-sm-body-2">
          <!-- data -->
          <v-col cols="6" lg="4">
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.date") }}</div>
              <div class="f-value">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("DD/MM/YYYY")
                }}
              </div>
            </div>

            <!-- order number -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.orderNumber") }}</div>
              <div class="f-value">
                {{ order.orderId }}
              </div>
            </div>

            <!-- order type -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.type") }}</div>
              <div class="d-flex align-center default--text font-weight-bold">
                <img
                  aria-hidden
                  :src="serviceImg"
                  height="32"
                  width="37"
                  class="mr-2"
                />
                <span class="text-capitalize"
                  >{{ order.shippingAddress.addressName }}
                </span>
              </div>
            </div>
          </v-col>
          <v-divider class="px-0 d-none d-md-flex" vertical></v-divider>

          <!-- totale -->
          <v-col cols="6" sm="6" lg="4" class="pl-10">
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.total") }}</div>
              <div class="f-value">{{ $n(order.grossTotal, "currency") }}</div>
            </div>

            <!-- stato -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.status") }}</div>
              <div class="f-value">
                <OrderStatusBadge v-bind:order="order" />
              </div>
            </div>

            <!-- pagamento -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.paymentType") }}</div>
              <div class="f-value">
                {{ $t("paymentType." + order.paymentTypeId) }}
              </div>
            </div>
          </v-col>

          <!-- buttons -->
          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-wrap justify-end mt-2 row-gap"
          >
            <div class="d-flex flex-wrap justify-end col-gap">
              <v-btn
                small
                depressed
                color="primary"
                height="40"
                v-bind:to="'/profile/orders/' + order.orderId"
              >
                {{ $t("orders.button.viewDetails") }}
              </v-btn>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    depressed
                    color="primary"
                    height="40"
                    v-if="order.isPayable"
                    v-bind:to="'/profile/orders/' + order.orderId"
                  >
                    <v-icon color="white">$creditcard</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.retryPayment") }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="editOrder(order.orderId)"
                    depressed
                    small
                    color="primary"
                    height="40"
                    v-if="order.isEditable"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.modifyOrder") }}</span>
              </v-tooltip>
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    depressed
                    color="primary"
                    height="40"
                    class="ml-1"
                    @click.stop.prevent="deleteOrder(order.orderId)"
                    v-if="order.isDeletable"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.cancelOrder") }}</span>
              </v-tooltip>

              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    depressed
                    color="primary"
                    height="40"
                    v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
                    @click.stop.prevent="addAllToCart(order.orderId)"
                  >
                    <v-icon>$cart</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.reorderOrder") }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.orders-table-container {
  .active {
    background-color: var(--v-primary-lighten2) !important;
  }
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
  .field-wrapper {
    margin-bottom: 15px;
    font-size: 14px;
    .f-value {
      font-weight: bold;
      span {
        align-items: center;
      }
    }
  }
  .col-gap {
    column-gap: 15px;
  }
  .row-gap {
    row-gap: 15px;
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";

import cartInfo from "~/mixins/cartInfo";

import { mapState, mapGetters } from "vuex";

export default {
  name: "OrdersTable",
  props: {
    orders: {
      type: Array
    },
    delivered: {
      type: Boolean
    }
  },
  mixins: [cartInfo],
  components: {
    OrderStatusBadge
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", orderId);
    }
  },
  mounted() {}
};
</script>
